<template>
  <div
    class="progress-container"
    role="progressbar"
    :aria-valuenow="value"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-label="ariaLabel"
  >
    <div class="progress-bar-background"></div>
    <div class="progress-bar" :style="progressBarStyle"></div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  value: number;
  ariaLabel: string;
}

const props = defineProps<Props>();

const progressBarStyle = computed(() => ({
  transform: `scaleX(${props.value / 100})`,
}));
</script>

<style scoped lang="scss">
.progress-container {
  height: 5px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
}

.progress-bar-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(var(--progress-bar-color, var(--v-theme-primary)));
  opacity: var(--v-border-opacity);
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: rgba(var(--progress-bar-color, var(--v-theme-primary)));
  transform-origin: left center;
  transition: transform 0.01s linear;
}
</style>
